import { LoadingIndicator, ProgressBar } from '@Wonder-Cave/ui';
import { AltBusinessIdType, IClientEntity, IHttpResponse, ISaveBrandClientRequest, ISearchClientsRecord, TcrEntityType } from '@shared/models';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosGet, axiosPost, getQueryParamString } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useTcrExchanges from '../../hooks/useTcrExchanges';
import useTcrPartners from '../../hooks/useTcrPartners';
import useTcrVerticals from '../../hooks/useTcrVerticals';
import useTenants from '../../hooks/useTenants';
import { sortByLabel } from '../../providers/utility.provider';
import { IDropdownValue } from '../shared/Form/Dropdown';
import ClientCompanyInfoForm from './Steps/ClientCompanyInfoForm';
import ClientContactForm from './Steps/ClientContactForm';
import TokenForm from './Steps/TokenForm';
import { IClientContactForm, ICompanyInfoForm, ITokenForm, usCountryOption } from './types';



const SaveClient = () => {
  const [clientLoading, setClientLoading] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [clientData, setClientData] = useState<ISaveBrandClientRequest>({} as ISaveBrandClientRequest);
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const [{ data: tcrExchanges, loading: tcrExchangesLoading }] = useTcrExchanges();
  const [{ data: tcrPartners, loading: tcrPartnersLoading }] = useTcrPartners();
  const [{ data: tcrVerticals, loading: tcrVerticalsLoading }] = useTcrVerticals();
  const [{ data: tenants, loading: tenantsLoading }] = useTenants();

  const loading = clientLoading || tcrExchangesLoading || tcrPartnersLoading || tcrVerticalsLoading || tenantsLoading;

  const exchangeOptions: IDropdownValue[] = sortByLabel(tcrExchanges?.map(ex => ({ label: ex, value: ex })) ?? []);
  const tenantOptions: IDropdownValue[] = sortByLabel(tenants?.map(t => ({ label: `${t.name}`, value: t.id, additionalData: { orgId: t.orgId, environment: t.environment.name, grTenantId: t.GRtenantId } })) ?? []);
  const partnerOptions: IDropdownValue[] = sortByLabel(tcrPartners ? Array.from(Object.entries(tcrPartners) ?? []).map(entry => ({ label: entry[0], value: entry[1] })) : []);
  const verticalOptions: IDropdownValue[] = sortByLabel(tcrVerticals ? Array.from(Object.entries(tcrVerticals) ?? []).map(entry => ({ label: entry[0], value: entry[1] })) : []);

  const getClient = async () => {
    setDuplicateLoading(true);
    try {
      const response = await axiosGet<ISearchClientsRecord>(`/clients/${location.state.id}?${getQueryParamString({ environment: location?.state?.environment })}`);
      if (response.status === 200) {
        const client = response.data;
        setClientData(prevState => ({
          ...prevState,
          companyName: client.tcrBrand?.companyName ?? '',
          brandName: client.tcrBrand?.companyName ?? '',
          tcrEntityType: TcrEntityType[client.tcrBrand?.entityType ?? ''],
          taxId: client.tcrBrand?.ein ?? '',
          altBusinessIdType: client.tcrBrand?.altBusinessIdType,
          altBusinessId: client.tcrBrand?.altBusinessId
        }));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDuplicateLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      const client: ISearchClientsRecord = { ...location.state };
      setClientData(prevState => ({
        ...prevState,
        clientName: client.name,
        tenantId: client.tenantId ?? '',
        orgId: client.tenant?.authId ?? '',
        defaultClickerGroupId: client.defaultClickerGroupId ?? undefined,
        senderIdentifier: client.senderIdentifier,
        externalId: client.externalId,
        messageFlow: client.messageFlow,
        privacyPolicyUrl: client.privacyPolicyUrl,
        termsAndConditionsUrl: client.termsAndConditionsUrl,
        twoWayEnabled: client.twoWayEnabled,
        areaCode: client.areaCode,
        tcrBrandId: client.tcrBrandId
      }));
      getClient();
    }
  }, [location.state]);

  async function saveCompanyInfo(companyInfo: ICompanyInfoForm): Promise<void> {
    if (!companyInfo.tcrEntityType && !companyInfo.tcrBrandId) {
      throw new Error('entity type not selected');
    }

    if (!companyInfo.tenantId) {
      throw new Error('tenant Id not selected');
    }

    setClientData(prevState => ({
      ...prevState,
      companyName: companyInfo.companyName,
      brandName: companyInfo.brandName,
      senderIdentifier: companyInfo.senderIdentifier ?? '',
      externalId: companyInfo.externalId ?? '',
      messageFlow: companyInfo.messageFlow ?? '',
      twoWayEnabled: companyInfo.twoWayEnabled,
      tenantId: companyInfo.tenantId!,
      defaultClickerGroupId: companyInfo?.defaultClickerGroupId,
      clientName: companyInfo.clientName,
      tcrEntityType: TcrEntityType[companyInfo.tcrEntityType ?? ''],
      registrationCountryCode: companyInfo.registrationCountryCode?.value,
      taxId: companyInfo.taxId,
      taxIdCountryCode: companyInfo.taxIdCountryCode?.value,
      altBusinessId: companyInfo.altBusinessId,
      altBusinessIdType: AltBusinessIdType[companyInfo.altBusinessIdType ?? ''],
      tcrBrandId: companyInfo.tcrBrandId,
      areaCode: companyInfo.areaCode ? Number(companyInfo.areaCode) : undefined,
      privacyPolicyUrl: companyInfo.privacyPolicyUrl,
      termsAndConditionsUrl: companyInfo.termsAndConditionsUrl
    }));

    if (companyInfo.tcrBrandId) {
      setClientLoading(true);

      try {
        const response = await axiosPost<IHttpResponse<IClientEntity>>(`/clients`, companyInfo);

        if (response?.status === 200) {
          navigate('/clients');
        }
      } catch (error: any) {
        console.error('Failed to save client:', error);
        console.error(error.response.data.error);
        addNotification({
          header: 'Error',
          content: error.response.data.error ?? 'An unexpected error occurred when attempting to save the Client.',
          type: NotificationType.FAILURE,
        });
        return;
      } finally {
        setClientLoading(false);
      }

      addNotification({
        header: 'Client saved successfully!',
      });
    }

    setStep(2);
  }

  function saveContactInfo(contact: IClientContactForm): void {
    if (!contact.verticalTypeCode) {
      throw new Error('vertical type not selected');
    }
    if (!contact.phoneNumber) {
      throw new Error('phone number not provided');
    }
    if (!contact.zipCode) {
      throw new Error('zip code not provided');
    }

    setClientData(prevState => ({
      ...prevState,
      url: contact.url,
      stockSymbol: contact.stockSymbol,
      stockExchange: contact.stockExchange,
      verticalTypeCode: contact.verticalTypeCode ?? '',
      referenceId: contact.referenceId,
      firstName: contact.firstName,
      lastName: contact.lastName,
      phoneNumber: contact.phoneNumber!,
      email: contact.email,
      countryCode: contact.countryCode?.value,
      zipCode: contact.zipCode!.toString(),
      usStateCode: contact.usStateCode ?? '',
      city: contact.city,
      street: contact.street
    }));

    setStep(3);
  }

  async function saveClient(token: ITokenForm): Promise<void> {
    setClientLoading(true);

    const finalClientData: ISaveBrandClientRequest = {
      ...clientData,
      partnerToken: token.partnerToken,
      partnerCode: token.partnerCode?.label,
      partnerId: token.partnerId,
      defaultClickerGroupId: isEmpty(clientData?.defaultClickerGroupId) ? undefined : clientData?.defaultClickerGroupId,
    };

    try {
      // TODO - handle update
      if (false) {
      } else {
        console.log('client data:', finalClientData);
        await createClient(finalClientData);
      }
    } catch (err: any) {
      console.error(err);
      console.error(err.error);
      addNotification({
        header: 'Error',
        content: err.error ?? 'An unexpected error occurred when attempting to save the Client.',
        type: NotificationType.FAILURE,
      });
      return;
    } finally {
      setClientLoading(false);
    }

    addNotification({
      header: 'Client saved successfully!',
    });
  };

  async function createClient(request: ISaveBrandClientRequest): Promise<void> {

    try {
      const response = await axiosPost<IHttpResponse<IClientEntity>>('/brand-clients', request);

      if (response?.status === 200) {
        navigate('/clients');
      }
    } catch (error: any) {
      error.error = error.response.data.error;
      throw error;
    }
  };

  function onBack() {
    step === 1
      ? history.back()
      : setStep((prevState) => prevState - 1);
  }

  return (
    <>
      <h1 id="clients-title" className="flex justify-between pb-6 px-28">
        Add Client
      </h1>

      <div className="flex pb-2">
        <ProgressBar full steps={[
          { num: 1, description: 'Company Info', active: step === 1 },
          { num: 2, description: 'Contact Info', active: step === 2 },
          { num: 3, description: 'Token', active: step === 3 }
        ]}
        />
      </div>

      <div className="pb-8 mt-12 px-28">
        {(step === 1 && tenantOptions.length > 0) ? <ClientCompanyInfoForm existingValues={{
          companyName: clientData.companyName,
          defaultClickerGroupId: clientData.defaultClickerGroupId,
          tenantId: location.state ? (tenantOptions.find(t => t.additionalData.grTenantId === clientData.tenantId)?.value ?? clientData.tenantId) : clientData.tenantId,
          orgId: clientData.orgId,
          senderIdentifier: clientData.senderIdentifier,
          messageFlow: clientData.messageFlow,
          externalId: clientData.externalId,
          brandName: clientData.brandName ?? '',
          clientName: clientData.clientName,
          isActive: clientData.isActive ?? true,
          twoWayEnabled: clientData.twoWayEnabled,
          tcrEntityType: clientData.tcrEntityType,
          tcrBrandId: clientData.tcrBrandId,
          registrationCountryCode: usCountryOption,
          taxId: clientData.taxId,
          taxIdCountryCode: usCountryOption,
          altBusinessIdType: clientData.altBusinessIdType,
          altBusinessId: clientData.altBusinessId,
          areaCode: clientData.areaCode?.toString(),
          privacyPolicyUrl: clientData.privacyPolicyUrl,
          termsAndConditionsUrl: clientData.termsAndConditionsUrl
        }} tenantOptions={tenantOptions} onBack={onBack} onSubmit={saveCompanyInfo} duplicateLoading={duplicateLoading} saveLoading={clientLoading} /> : <LoadingIndicator position='CENTER' vPosition='CENTER' size={16} />}
        {step === 2 && <ClientContactForm
          existingValues={{
            url: clientData.url,
            email: clientData.email,
            street: clientData.street,
            city: clientData.city,
            usStateCode: clientData.usStateCode,
            zipCode: parseInt(clientData.zipCode ?? 0),
            countryCode: usCountryOption,
            stockSymbol: clientData.stockSymbol,
            stockExchange: clientData.stockExchange,
            verticalTypeCode: clientData.verticalTypeCode,
            referenceId: clientData.referenceId,
            firstName: clientData.firstName,
            lastName: clientData.lastName,
            phoneNumber: clientData.phoneNumber
          }}
          exchangeOptions={exchangeOptions} verticalTypeOptions={verticalOptions} onBack={onBack} onSubmit={saveContactInfo} tcrEntityType={clientData.tcrEntityType} />}
        {step === 3 && <TokenForm partnerOptions={partnerOptions} onBack={onBack} onSubmit={saveClient} />}
      </div>
    </>
  );
};

export default SaveClient;
